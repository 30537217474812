<template>
  <div class="set-pass-container">
    <b-container class="main-container">
      <form
        v-on:submit="onSubmit"
        novalidate
        @submit.prevent="validateBeforeSubmit"
      >
        <b-container class="set-up-pass-header">
          <b-row class="mt-4 text-center justify-content-center">
            <b-col>
              <h5 class="set-up-your-pass">RESET YOUR PASSWORD</h5>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-md-center">
            <b-col>
              <p class="pass-lengyh-log">Please enter your email address</p>
            </b-col>
          </b-row>
          <b-row
            ><b-alert
              :show="showErrorAlert"
              dismissible
              variant="danger"
              @dismissed="showErrorAlert = false"
            >
              <span>
                <ul>
                  <li v-for="(err, i) in error" :key="i">
                    {{ err }}
                  </li>
                </ul>
              </span>
            </b-alert></b-row
          >
          <b-row class="mt-3 justify-content-center">
            <b-col v-if="showSuccess">
              <p class="send-email-success">
                The link  will be sent to your email address to reset the
                password if it was found
              </p>
            </b-col>
            <b-col v-else cols="12" sm="6">
              <b-input
                v-validate="'required|email'"
                name="email"
                type="text"
                v-model.trim="email"
                autocorrect="off"
                autocapitalize="off"
                autocomplete="off"
                id="email"
                class="set-pass-input"
                placeholder="email*"
                :class="{ 'is-danger': errors.has('email') }"
              ></b-input>
              <small v-if="errors.has('email')" class="text-field is-danger">
                {{ errors.first("email") }}
              </small>
            </b-col>
          </b-row>
          <b-row class="mt-3 justify-content-center">
            <b-col cols="4">
              <b-button
                v-if="!showSuccess"
                class="set-password-button"
                block
                :disabled="false"
                type="submit"
                >DONE</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </form>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      error: null,
      showErrorAlert: false,
      email: '',
      showSuccess: false,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      await this.$validator.validate();
    },
    ...mapActions({
      forgotPassword: 'user/forgotPassword',
    }),
    async onSubmit(evt) {
      evt.preventDefault();
      const { email } = this;
      const isValide = await this.$validator.validate();
      if (isValide) {
        try {
          await this.forgotPassword({ email });
          this.showSuccess = true;
        } catch (e) {
          console.log(e);
          if (e.response) {
            this.error = e.response.data.email;
            this.showErrorAlert = true;
          }
        }
      }
    },
  },
};
</script>

<style>
.set-pass-container {
  min-height: calc(70vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.9;
}
.set-pass-input {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #333333;
  box-shadow: none !important;
  border-radius: 2px;
  border: solid 1px #aaaaaa;
}
.text-field {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ff0000;
  transition: 0.5s;
}
.main-container {
  background-color: #ffffff;
}
.set-password-button {
  margin-bottom: 55px;
  background-color: #bd9e5f;
}
.set-up-your-pass {
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.pass-lengyh-log {
  font-family: Arial;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
}
.set-up-pass-header {
  border-bottom: #ebebeb 2px solid;
}
.send-email-success {
  font-family: Arial;
  line-height: 1.13;
  text-align: center;
  color: #666666;
  font-size: 17px;
}
</style>
